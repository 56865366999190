import type { ArticleImageProps } from '@/app/components/ArticleCardsModule';
import type { ArticleCardDataProps } from '@/app/components/ArticleCardsModule/ArticleCard';

export const ARTICLE_CARDS: ArticleCardDataProps[] = [
  {
    heading: 'press_item_1_title_2023',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.cnet.com/tech/mobile/oura-ring-can-now-tell-if-youre-a-morning-person/',
      rel: 'noopener noreferrer',
      siteName: 'CNET',
      target: '_blank',
    },
  },
  {
    heading: 'press_item_2_title_2023',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.esquire.com/lifestyle/a42354480/oura-ring-review/',
      rel: 'noopener noreferrer',
      siteName: 'Esquire',
      target: '_blank',
    },
  },
  {
    heading: 'press_item_3_title_2023',
    buttonProps: {
      ariaLabel: 'press_read_article',
      external: true,
      color: 'dark',
      href: 'https://www.goodhousekeeping.com/health-products/a42015954/2023-fitness-awards/',
      rel: 'noopener noreferrer',
      siteName: 'Good Housekeeping',
      target: '_blank',
    },
  },
];

export const ARTICLE_IMAGE: ArticleImageProps = {
  alt: 'press_section_primary_image_alt',
  buttonProps: {
    external: true,
    href: 'https://www.cnbc.com/2022/05/17/these-are-the-2022-cnbc-disruptor-50-companies.html',
    label: 'read_more',
    rel: 'noopener noreferrer',
    siteName: 'CNBC',
    target: '_blank',
    variant: 'secondary-light',
  },
  heading: 'home_awards_cnbc_disruptor',
  src: 'blue-sky/home/press',
};
