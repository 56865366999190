import {
  BREAKPOINTS_V3,
  ButtonV3,
  CardButton,
  GridItem,
  TypographyV3,
  useGoToSwipeableSlide,
  useMediaQuery,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import {
  AnimatePresence,
  useReducedMotion,
  useScroll,
  useTransform,
} from 'framer-motion';
import { useTranslations } from 'next-intl';
import type { KeyboardEventHandler } from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { EventType } from '@/analytics/types';
import BackdropBlur from '@/app/components/BackdropBlur';
import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';

import type { Slide as SlideType } from './types';

export interface HomeSliderItemProps {
  readonly expandAtId: number;
  readonly slide: SlideType;
  readonly onExpand: (id: number) => void;
  readonly onOverlayOpen: (id: number) => void;
}

const HomeSliderItem = ({
  expandAtId,
  slide,
  onExpand,
  onOverlayOpen,
}: HomeSliderItemProps): JSX.Element => {
  const container = useRef<HTMLDivElement>(null);
  const target = useRef<HTMLDivElement>(null);
  const isLargeScreen = useMediaQuery(`(min-width: ${BREAKPOINTS_V3.large}px)`);
  const shouldReduceMotion = useReducedMotion();
  const Icon = slide.tag.icon;

  // parallax on image
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'end start'],
    layoutEffect: false,
  });
  const scrollY = useTransform(scrollYProgress, [0, 1], ['-20%', '10%']);
  const t = useTranslations();
  const tagLabel = t(slide.tag.label);
  const { goToSlide } = useGoToSwipeableSlide();

  // sync the current slide with CardButton mouse click
  const handleChange = useCallback(
    (id: number) => {
      goToSlide(id);
    },
    [goToSlide],
  );

  // open/close expand or overlay on keydown
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { code, shiftKey } = event;

      if (code === 'Escape' || code === 'Enter' || code === 'Space') {
        if (isLargeScreen) {
          onExpand(slide.id);
        } else {
          onOverlayOpen(slide.id);
        }
        goToSlide(slide.id);
      } else if (shiftKey && code === 'Tab') {
        if (isLargeScreen) {
          onExpand(-1);
        }
        goToSlide(slide.id > 0 ? slide.id - 1 : 0);
      }
    },
    [goToSlide, isLargeScreen, onExpand, onOverlayOpen, slide.id],
  );

  // close current slide if shift tab is pressed on learn more button
  const handleLearnMoreKeyDown: KeyboardEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    const { code, shiftKey } = event;

    if (!shiftKey && code === 'Tab') {
      onExpand(-1);
    }
  };

  const handleLearnMoreClick = async (): Promise<void> => {
    await window.ouraAnalytics.track(EventType.LinkClicked, {
      cta: 'learn more',
      location: 'home membership slider',
      path: slide.button.href,
    });
  };

  useEffect(() => {
    if (!container.current) return;
    const current = container.current;
    current.addEventListener('keydown', handleKeyDown);
    return () => {
      current.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      aria-label={t(slide.tag.label)}
      className={cx(
        'motion-safe:transition-all motion-safe:duration-500 motion-safe:ease-in-out',
        'h-[388px] w-[80vw] max-w-[1440px] pb-8 md:h-[658px]',
        {
          'w-[80vw]': expandAtId === slide.id,
          'md:w-[493px]': expandAtId !== slide.id,
        },
      )}
      ref={container}
    >
      <div
        className={cx('h-full w-full overflow-hidden rounded-xl', {
          active: expandAtId === slide.id,
        })}
        ref={target}
      >
        <div className="grid h-full grid-cols-4 grid-rows-4 gap-4">
          <GridItem
            className="relative"
            colEnd={{ sm: 5 }}
            colStart={{ sm: 1 }}
            rowEnd={{ sm: 5 }}
            rowStart={{ sm: 1 }}
          >
            <Motion
              data-cy="home-slider-image"
              style={{
                y: shouldReduceMotion ? 0 : scrollY,
                height: shouldReduceMotion ? '100%' : '110%',
                position: 'relative',
              }}
            >
              <Image
                className="object-cover"
                draggable={false}
                src={slide.image.desktop.src}
                alt={t(slide.image.desktop.alt)}
                fill
                sizes="(max-width: 768px) 90vw, (max-width: 1200px) 25vw, 35vw"
              />
            </Motion>
          </GridItem>
          <GridItem
            className="pl-6 pt-6"
            colEnd={{ sm: 4 }}
            colStart={{ sm: 1 }}
            rowEnd={{ sm: 2 }}
            rowStart={{ sm: 1 }}
          >
            <div
              className={cx(
                'relative inline-flex items-center gap-x-2.5 overflow-hidden',
                'px-4 py-3 md:px-6 md:py-4',
              )}
            >
              <BackdropBlur variant="pill" />
              <Icon className="relative inline-block w-6 text-sandstone-200" />
              <TypographyV3
                Element="span"
                color="light"
                variant="eyebrow-small"
                className="relative"
              >
                {t(slide.tag.label)}
              </TypographyV3>
            </div>
          </GridItem>
          <GridItem
            className="relative flex flex-col items-end pr-6 pt-6"
            colEnd={{ sm: 5 }}
            colStart={{ sm: 4 }}
            rowEnd={{ sm: 2 }}
            rowStart={{ sm: 1 }}
          >
            {/* mobile overlay trigger */}
            <CardButton
              aria-expanded={false}
              ariaLabel={t('expand_card_aria_label', {
                tag: tagLabel,
              })}
              className="md:!p-5 lg:hidden"
              color="light"
              open={false}
              size="large"
              variant="expand"
              onMouseDown={() => {
                onOverlayOpen(slide.id);
              }}
            />
            {/* desktop expand button */}
            <CardButton
              aria-expanded={expandAtId === slide.id}
              ariaLabel={
                slide.id === expandAtId
                  ? t('collapse_card_aria_label')
                  : t('expand_card_aria_label', {
                      tag: tagLabel,
                    })
              }
              className="hidden lg:block"
              color="light"
              open={expandAtId === slide.id}
              size="xlarge"
              variant="expand"
              onMouseDown={() => {
                if (expandAtId !== slide.id) {
                  handleChange(slide.id);
                }
                onExpand(slide.id);
              }}
            />
          </GridItem>
          <GridItem
            className="relative max-w-[400px] self-end px-6 pb-6 md:pr-0"
            colEnd={{ sm: 5, md: 5, lg: 4 }}
            colStart={{ sm: 1, md: 1, lg: 1 }}
            rowEnd={{ sm: 5 }}
            rowStart={{ sm: 4 }}
          >
            <AnimatePresence>
              {expandAtId === slide.id ? (
                <div>
                  <Motion
                    data-cy="home-slider-right-content"
                    key={slide.id}
                    initial={{ y: shouldReduceMotion ? 0 : 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: shouldReduceMotion ? 0 : 100, opacity: 0 }}
                    transition={{ duration: 1, type: 'tween' }}
                  >
                    <TypographyV3
                      key={slide.id}
                      Element="h3"
                      variant="h4"
                      color="light"
                      height="none"
                    >
                      {t.rich(slide.title)}
                    </TypographyV3>
                    <TypographyV3
                      Element="p"
                      variant="body-large"
                      color="light"
                      height="snug"
                      className="my-6 hidden lg:block"
                    >
                      {t(slide.description)}
                    </TypographyV3>
                    <ButtonV3
                      aria-label={t('learn_more_aria_label', { tag: tagLabel })}
                      className="relative z-100 hidden lg:inline-block"
                      data-cy="learn-more-button"
                      href={slide.button.href}
                      rel="noopener noreferrer"
                      target="_blank"
                      variant="secondary-light"
                      onClick={handleLearnMoreClick}
                      onKeyDown={handleLearnMoreKeyDown}
                    >
                      {t(slide.button.label)}
                    </ButtonV3>
                  </Motion>
                </div>
              ) : (
                <TypographyV3
                  Element="h3"
                  variant="h4"
                  color="light"
                  height="none"
                >
                  {t.rich(slide.title)}
                </TypographyV3>
              )}
            </AnimatePresence>
          </GridItem>
          <AnimatePresence>
            {expandAtId === slide.id ? (
              <GridItem
                key={slide.id}
                className="hidden self-end pb-6 pr-6 lg:block"
                colEnd={{ sm: 5 }}
                colStart={{ sm: 4 }}
                rowEnd={{ sm: 5 }}
                rowStart={{ sm: 2 }}
              >
                <div className="relative mb-6 p-4">
                  <BackdropBlur
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5, type: 'tween' }}
                    variant="card"
                  />
                  <Motion
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5, type: 'tween' }}
                  >
                    <div className="relative mb-6 flex items-center gap-x-4">
                      <Image
                        src={slide.quote.image.src}
                        alt={t(slide.quote.image.alt)}
                        width={50}
                        height={50}
                        format="png"
                      />
                      <TypographyV3 Element="div" color="light">
                        {slide.quote.author}
                      </TypographyV3>
                    </div>
                    <TypographyV3
                      Element="div"
                      className="relative"
                      font="serif"
                      variant="body-large"
                      color="light"
                    >
                      {t(slide.quote.content)}
                    </TypographyV3>
                  </Motion>
                </div>
                <div className="relative text-sandstone-200">
                  <BackdropBlur
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5, type: 'tween' }}
                    variant="card"
                  />
                  <Motion
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5, type: 'tween' }}
                  >
                    <Image
                      alt=""
                      className="relative"
                      src={t(slide.app.src)}
                      format="png"
                      width={300}
                      height={296}
                    />
                  </Motion>
                </div>
              </GridItem>
            ) : null}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default HomeSliderItem;
