import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';

import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';

import MobileFeature from './MobileFeature';

const TakeAWalk = (): JSX.Element => {
  const t = useTranslations();
  return (
    <MobileFeature
      title="features_tab_2_label"
      image={{
        alt: 'tab_item_take_walk_img_aria_label',
        src: 'blue-sky/home/take-walk',
      }}
    >
      <div className="relative hidden lg:block" data-cy="take-a-walk">
        <div className="hidden grid-cols-1 grid-rows-1 justify-items-end lg:grid">
          <Image
            alt=""
            src="blue-sky/home/take-walk-mask-1"
            height={81}
            width={340}
            className="col-start-1 col-end-2 row-start-1 row-end-2 my-6 rounded-lg"
          />
          <Image
            alt={t('stretch_legs_image_alt')}
            src={t('stretch_legs_notification')}
            format="png"
            height={81}
            width={340}
            className="col-start-1 col-end-2 row-start-1 row-end-2 my-6 rounded-lg"
          />
        </div>
        <Image
          alt={t('tab_item_take_walk_img_aria_label')}
          src="blue-sky/home/take-walk"
          height={510}
          width={605}
          className="rounded-lg object-cover"
        />
      </div>
      <div
        className={cx(
          'absolute bottom-16 left-1/2 w-[80vw] max-w-[300px] -translate-x-1/2 flex-col gap-y-6',
          'lg:relative lg:bottom-auto lg:left-0 lg:flex lg:w-auto lg:max-w-none lg:translate-x-0',
        )}
      >
        <div className="hidden grid-cols-1 grid-rows-1 lg:grid">
          <Image
            alt=""
            className="col-start-1 col-end-2 row-start-1 row-end-2 rounded-lg"
            src="blue-sky/home/take-walk-mask-2"
            width={350}
            height={223}
          />
          <Image
            alt={t('heart_rate_image_2_alt')}
            className="col-start-1 col-end-2 row-start-1 row-end-2 rounded-lg"
            src={t('heart_rate_app_ui_2')}
            format="png"
            width={350}
            height={223}
          />
        </div>
        <div className="hidden grid-cols-1 grid-rows-1 lg:grid">
          <Image
            alt=""
            className="col-start-1 col-end-2 row-start-1 row-end-2 rounded-lg"
            src="blue-sky/home/take-walk-mask-3"
            width={300}
            height={311}
          />
          <Image
            alt={t('activity_goal_image_alt')}
            className="col-start-1 col-end-2 row-start-1 row-end-2 rounded-lg"
            src={t('activity_goal_app_ui')}
            format="png"
            width={300}
            height={305}
          />
        </div>
        <Motion
          initial={{ opacity: 0, scale: 0.95 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, type: 'tween', delay: 0.5 }}
          className="grid grid-cols-1 grid-rows-1 lg:hidden"
        >
          <Image
            alt=""
            className="col-start-1 col-end-2 row-start-1 row-end-2 h-full rounded-lg"
            src="blue-sky/home/take-walk-mask-mobile"
            width={300}
            height={277}
          />
          <Image
            alt={t('activity_goal_image_alt')}
            className="col-start-1 col-end-2 row-start-1 row-end-2 rounded-lg"
            src={t('activity_goal_app_ui')}
            format="png"
            width={300}
            height={277}
          />
        </Motion>
      </div>
    </MobileFeature>
  );
};

export default TakeAWalk;
