import { cx } from 'class-variance-authority';

import Motion, { type MotionProps } from '../Motion';

type BackdropBlurAnchorX = 'left' | 'right';

type BackdropBlurAnchorY = 'top' | 'bottom';

type BackdropBlurColor = 'dark' | 'default' | 'extra-dark' | 'light';

type BackdropBlurOpacity = 0.1 | 0.4 | 0.7 | 1;

type BackdropBlurVariant = 'card' | 'chiclet' | 'pill';

export interface BackdropBlurProps extends MotionProps {
  readonly anchor?: {
    x: BackdropBlurAnchorX;
    y: BackdropBlurAnchorY;
  };
  readonly className?: string | undefined;
  readonly color?: {
    sm: BackdropBlurColor;
    lg: BackdropBlurColor;
  };
  readonly opacity?: {
    sm: BackdropBlurOpacity;
    lg: BackdropBlurOpacity;
  };
  readonly size?: {
    height: number;
    width: number;
  };
  readonly variant: BackdropBlurVariant;
}

const DEFAULT_ANCHOR: BackdropBlurProps['anchor'] = {
  x: 'left',
  y: 'top',
};

const DEFAULT_COLOR: BackdropBlurProps['color'] = {
  sm: 'default',
  lg: 'default',
};

const DEFAULT_OPACITY: BackdropBlurProps['opacity'] = {
  sm: 0.1,
  lg: 0.1,
};

export const getAnchorClasses = ({
  anchor,
}: Pick<BackdropBlurProps, 'anchor'>): Record<string, boolean> => ({
  'left-0': anchor!.x === 'left',
  'right-0': anchor!.x === 'right',
  'bottom-0': anchor!.y === 'bottom',
  'top-0': anchor!.y === 'top',
});

export const getBgColorClasses = ({
  color,
}: Pick<BackdropBlurProps, 'color'>): Record<string, boolean> => ({
  'bg-[#222428]': color!.sm === 'dark',
  'bg-sandstone-200': color!.sm === 'default',
  'bg-[#151619]': color!.sm === 'extra-dark',
  'bg-white': color!.sm === 'light',
  'lg:bg-[#222428]': color!.lg === 'dark',
  'lg:bg-sandstone-200': color!.lg === 'default',
  'lg:bg-[#151619]': color!.lg === 'extra-dark',
  'lg:bg-white': color!.lg === 'light',
});

export const getBgOpacityClasses = ({
  opacity,
}: Pick<BackdropBlurProps, 'opacity'>): Record<string, boolean> => ({
  'bg-opacity-10': opacity!.sm === 0.1,
  'bg-opacity-40': opacity!.sm === 0.4,
  'bg-opacity-70': opacity!.sm === 0.7,
  'bg-opacity-100': opacity!.sm === 1,
  'lg:bg-opacity-10': opacity!.lg === 0.1,
  'lg:bg-opacity-40': opacity!.lg === 0.4,
  'lg:bg-opacity-70': opacity!.lg === 0.7,
  'lg:bg-opacity-100': opacity!.lg === 1,
});

export const getBorderRadiusClasses = ({
  variant,
}: Pick<BackdropBlurProps, 'variant'>): Record<string, boolean> => ({
  'rounded-xl': variant === 'card' || variant === 'chiclet',
  'rounded-3xl': variant === 'pill',
});

export const getAspectRatio = (
  size?: BackdropBlurProps['size'],
): string | undefined => {
  const hasSize = size !== undefined;
  return hasSize ? `${size.width}/${size.height}` : undefined;
};

const BackdropBlur = ({
  anchor = DEFAULT_ANCHOR,
  className,
  color = DEFAULT_COLOR,
  opacity = DEFAULT_OPACITY,
  size,
  style,
  variant,
  ...props
}: BackdropBlurProps): JSX.Element => {
  return (
    <Motion
      {...props}
      aria-hidden="true"
      className={cx(
        'absolute min-h-px bg-blend-multiply backdrop-blur-xl',
        {
          ...getAnchorClasses({ anchor }),
          ...getBgColorClasses({ color }),
          ...getBgOpacityClasses({ opacity }),
          ...getBorderRadiusClasses({ variant }),
          'size-full': !size,
          'w-full': !!size,
        },
        className,
      )}
      data-cy="backdrop_blur"
      style={{
        ...style,
        aspectRatio: getAspectRatio(size),
      }}
    />
  );
};

export default BackdropBlur;
