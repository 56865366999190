import {
  CardButton,
  type CardButtonProps,
  TypographyV3,
} from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import type { MouseEventHandler } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

export interface ArticleCardDataProps {
  readonly heading: MessageKey;
  readonly buttonProps: CardButtonProps;
}

export interface ArticleCardProps extends ArticleCardDataProps {
  readonly onClick: MouseEventHandler<HTMLAnchorElement>;
}

const ArticleCard = ({
  heading,
  buttonProps,
  onClick,
}: ArticleCardProps): JSX.Element => {
  const t = useTranslations();
  const { ariaLabel, siteName, ...rest } = buttonProps;

  return (
    <article className="flex h-full w-[75vw] flex-col justify-between overflow-hidden rounded-lg bg-sandstone-200 p-6 md:w-full">
      <TypographyV3
        Element="h3"
        color="inherit"
        variant="unset"
        className="mb-36 text-heading-sm md:mb-12"
        height="none"
      >
        {t.rich(heading)}
      </TypographyV3>
      <div className="flex justify-end">
        <CardButton
          aria-label={t(ariaLabel)}
          data-site-name={siteName}
          {...rest}
          onClick={onClick}
        />
      </div>
    </article>
  );
};

export default ArticleCard;
