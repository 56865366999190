import {
  ActivityIcon,
  HeartIcon,
  HeartRangeMetricIcon,
  MeditationIcon,
  MoonIcon,
  WellnessIcon,
} from '@jouzen/ecom-components';
import type { SVGIconComponent } from 'types/svgr';

import type { Slide } from '../types';

export const SLIDER_CONTENT: Slide[] = [
  {
    app: {
      alt: 'sleep_app_image_alt',
      src: 'sleep_rest_app_ui',
    },
    button: {
      label: 'learn_more',
      href: 'https://ouraring.com/blog/category/sleep/',
    },
    description: 'homepage_sleep_hsa_compliance_copy',
    id: 0,
    image: {
      desktop: {
        src: 'blue-sky/home/membership-card-1-updated',
        alt: 'membership_sleep_card_image_alt',
      },
      mobile: {
        default: {
          src: 'blue-sky/home/membership-card-1-updated',
          alt: 'membership_sleep_card_image_alt',
        },
        overlay: {
          src: 'blue-sky/home/membership-card-1-overlay',
          alt: 'membership_sleep_card_image_alt',
        },
      },
    },
    tag: {
      label: 'membership_sleep_card_tag',
      icon: MoonIcon as SVGIconComponent,
    },
    title: 'membership_sleep_card_title',
    quote: {
      author: 'Lisa D.',
      content: 'membership_sleep_card_quote',
      image: {
        src: 'blue-sky/home/lisa-d-thumb',
        alt: 'membership_sleep_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'readiness_app_image_alt',
      src: 'wellness_app_ui',
    },
    button: {
      label: 'learn_more',
      href: 'https://ouraring.com/blog/category/health/',
    },
    description: 'membership_wellness_card_copy',
    id: 1,
    image: {
      desktop: {
        src: 'blue-sky/home/membership-card-2',
        alt: 'membership_wellness_card_image_alt',
      },
      mobile: {
        default: {
          src: 'blue-sky/home/membership-card-2',
          alt: 'membership_wellness_card_image_alt',
        },
        overlay: {
          src: 'blue-sky/home/membership-card-2-overlay',
          alt: 'membership_wellness_card_image_alt',
        },
      },
    },
    tag: {
      label: 'wellness_tag',
      icon: WellnessIcon as SVGIconComponent,
    },
    title: 'membership_wellness_card_title',
    quote: {
      author: 'Lindsay H.',
      content: 'membership_wellness_card_quote',
      image: {
        src: 'blue-sky/home/lindsay-h-thumb',
        alt: 'membership_wellness_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'activity_app_image_alt',
      src: 'activity_app_ui',
    },
    button: {
      label: 'learn_more',
      href: 'https://ouraring.com/blog/category/health/activity-movement/',
    },
    description: 'membership_activity_card_copy',
    id: 2,
    image: {
      desktop: {
        src: 'blue-sky/home/membership-card-3',
        alt: 'membership_activity_card_image_alt',
      },
      mobile: {
        default: {
          src: 'blue-sky/home/membership-card-3',
          alt: 'membership_activity_card_image_alt',
        },
        overlay: {
          src: 'blue-sky/home/membership-card-3-overlay',
          alt: 'membership_activity_card_image_alt',
        },
      },
    },
    tag: {
      label: 'membership_activity_card_tag',
      icon: ActivityIcon as SVGIconComponent,
    },
    title: 'membership_activity_card_title',
    quote: {
      author: 'Marcus B.',
      content: 'membership_activity_card_quote',
      image: {
        src: 'blue-sky/home/marcus-b-thumb',
        alt: 'membership_activity_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'heart_rate_app_image_alt',
      src: 'heart_rate_app_ui',
    },
    button: {
      label: 'learn_more',
      href: 'https://ouraring.com/blog/category/health/heart-health/',
    },
    description: 'membership_heart_card_copy',
    id: 3,
    image: {
      desktop: {
        src: 'blue-sky/home/membership-card-4',
        alt: 'membership_heart_card_image_alt',
      },
      mobile: {
        default: {
          src: 'blue-sky/home/membership-card-4',
          alt: 'membership_heart_card_image_alt',
        },
        overlay: {
          src: 'blue-sky/home/membership-card-4-overlay',
          alt: 'membership_heart_card_image_alt',
        },
      },
    },
    tag: {
      label: 'membership_heart_card_tag',
      icon: HeartIcon as SVGIconComponent,
    },
    title: 'membership_heart_card_title',
    quote: {
      author: 'Dave K.',
      content: 'membership_heart_card_quote',
      image: {
        src: 'blue-sky/home/dave-k-thumb',
        alt: 'membership_heart_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'cycle_insights_app_image_alt',
      src: 'cycle_tracking_app_ui',
    },
    button: {
      label: 'learn_more',
      href: 'https://ouraring.com/blog/category/health/womens-health/',
    },
    description: 'membership_women_health_card_copy',
    id: 4,
    image: {
      desktop: {
        src: 'blue-sky/home/membership-card-5',
        alt: 'membership_women_health_card_image_alt',
      },
      mobile: {
        default: {
          src: 'blue-sky/home/membership-card-5',
          alt: 'membership_women_health_card_image_alt',
        },
        overlay: {
          src: 'blue-sky/home/membership-card-5-overlay',
          alt: 'membership_women_health_card_image_alt',
        },
      },
    },
    tag: {
      label: 'membership_women_health_card_tag',
      icon: HeartRangeMetricIcon as SVGIconComponent,
    },
    title: 'membership_women_health_card_title',
    quote: {
      author: 'Cassidy N.',
      content: 'membership_women_health_card_quote',
      image: {
        src: 'blue-sky/home/cassidy-n-thumb',
        alt: 'membership_women_health_card_author_image_alt',
      },
    },
  },
  {
    app: {
      alt: 'daytime_stress_app_image_alt',
      src: 'stress_app_ui',
    },
    button: {
      label: 'learn_more',
      href: 'https://ouraring.com/blog/category/health/mental-health-stress/',
    },
    description: 'membership_stress_card_copy',
    id: 5,
    image: {
      desktop: {
        src: 'blue-sky/home/membership-card-6',
        alt: 'membership_stress_card_image_alt',
      },
      mobile: {
        default: {
          src: 'blue-sky/home/membership-card-6',
          alt: 'membership_stress_card_image_alt',
        },
        overlay: {
          src: 'blue-sky/home/membership-card-6-overlay',
          alt: 'membership_stress_card_image_alt',
        },
      },
    },
    tag: {
      label: 'membership_stress_card_tag',
      icon: MeditationIcon as SVGIconComponent,
    },
    title: 'membership_stress_card_title',
    quote: {
      author: 'Udo S.',
      content: 'membership_stress_card_quote',
      image: {
        src: 'blue-sky/home/udo-s-thumb',
        alt: 'membership_stress_card_author_image_alt',
      },
    },
  },
];
