import { useMotionValueEvent, useScroll, useTransform } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useRef } from 'react';

import { EventType } from '@/analytics/types';

const useScrollTracking = (): void => {
  const thresholdsReached = useRef<number[]>([0]);
  const path = usePathname();
  const { scrollYProgress } = useScroll();

  const scrollY = useTransform(scrollYProgress, [0, 1], [0, 1]);

  useMotionValueEvent(scrollY, 'change', (latest: number) => {
    const threshold = Math.round(latest * 10) / 10;

    if (!thresholdsReached.current.includes(threshold)) {
      thresholdsReached.current.push(threshold);

      void window.ouraAnalytics.track(EventType.PageScrolled, {
        page: path,
        threshold,
      });
    }
  });
};

export default useScrollTracking;
